import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {darken} from "polished";

import {Text} from "@pg-design/text";

import {ISelectGroupOption, SelectGroupOptionValueTypes} from "./SelectGroupField";

export type SelectGroupColorVariants = "default" | "gray";
interface IProps<ValueType extends SelectGroupOptionValueTypes> extends ISelectGroupOption<ValueType> {
    name: string;
    groupClassName?: string;
    onChange: (name: string, value: ValueType) => void;
    variant?: SelectGroupColorVariants;
}

// TODO: refactor this component to use a semantically correct checkbox
export const SelectGroupItem = <ValueType extends SelectGroupOptionValueTypes>(props: IProps<ValueType>) => {
    const onClick = () => {
        if (props.isDisabled) {
            return;
        }
        props.onChange(props.name, props.value);
    };

    return (
        <CheckboxHolder isActive={props.isActive} isNonActive={props.isNonActive} isDisabled={props.isDisabled} onClick={onClick} variant={props.variant}>
            <Text variant="info_txt_1" align="center">
                {props.label}
            </Text>
        </CheckboxHolder>
    );
};

interface ICheckboxHolder {
    isActive?: boolean;
    isNonActive?: boolean;
    isDisabled?: boolean;
    variant?: SelectGroupColorVariants;
}

const CheckboxHolder = styled.div<ICheckboxHolder>`
    background-color: #fff;
    padding: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    border-right: 1px solid ${(props) => props.theme.colors.gray[600]};
    transition: background-color 0.2s ease-in-out;

    ${(props) =>
        props.isDisabled
            ? css`
                  cursor: not-allowed;
                  color: #ccc;
              `
            : css`
                  cursor: pointer;
              `}
    background: ${(props) =>
        props.isActive && (props.isNonActive ? "#ececec" : props.variant === "gray" ? props.theme.colors.gray[300] : props.theme.colors.primary)};

    &:last-of-type {
        border-right: none;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        font-size: 14px;

        &:hover {
            background: ${(props) =>
                !props.isDisabled &&
                darken(
                    0.05,
                    props.isActive
                        ? props.isNonActive
                            ? "#ececec"
                            : props.variant === "gray"
                              ? props.theme.colors.gray[300]
                              : props.theme.colors.primary
                        : "#fff"
                )};
        }
    }
`;
