import React from "react";
import {css} from "@emotion/react";

import {FormikForm} from "@pg-design/formik-utils";
import {m} from "@pg-design/helpers-css";
import {Popover} from "@pg-design/notifications";
import {Text} from "@pg-design/text-module";
import {rpAppLink} from "@pg-mono/rp-routes";
import {useUserDevice} from "@pg-mono/user-device";

interface IProps {
    name: string;
    onFieldClick?: () => void;
    className?: string;
    popoverPlace?: "top" | "right" | "bottom" | "left";
}

export const RentalPriceField = (props: IProps) => {
    const {name, onFieldClick, className, popoverPlace} = props;
    const {isMobile} = useUserDevice();

    return (
        <Popover
            isActive
            popoverPlace={popoverPlace ? popoverPlace : isMobile ? "top" : "left"}
            body={<RentersCheckboxPopover />}
            toggleFocusHandler
            toggleOnClickOutside
            appendTo="parent"
            zIndex={10001} //popover on form is near phone input, select with prefix for phone number has z-index = 10000, set z-index to 10001 in case popover will cover phone input
        >
            <FormikForm.Checkbox
                name={name}
                labelContent={
                    <Text as="span" variant="info_txt_1">
                        Chcę zarabiać na tym mieszkaniu. Proszę o kontakt od eksperta rynku najmu krótkoterminowego.
                    </Text>
                }
                id="rentalPriceField"
                onClick={onFieldClick}
                className={className}
                checkedMarkColor="#02D054"
            />
        </Popover>
    );
};

const RentersCheckboxPopover = () => {
    return (
        <>
            <Text as="p" variant="info_txt_2" css={m(0, 0, 0.5, 0)}>
                Proszę o telefoniczny i niezobowiązujący kontakt ze strony specjalisty Renters Sp. z o.o. Zgodę mogę zawsze odwołać.
            </Text>

            <Text as="p" variant="info_txt_2" css={m(0, 0, 0.5, 0)}>
                <a href={rpAppLink.external.rentersPrivacyPolicy} target="_blank" css={popoverLink}>
                    Polityka Prywatności Renters Sp. z o.o.
                </a>
            </Text>
        </>
    );
};

const popoverLink = css`
    color: #fff;

    &:focus,
    &:hover {
        color: #fff;
    }
`;
