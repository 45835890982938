import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import {useFormikContext} from "formik";
import type {Dispatch} from "redux";

import {usePrevious} from "@pg-mono/hooks";

import {IRPStore} from "../../../app/rp_reducer";
import {ISentLeadActions, setDidSubmitOnMountAction} from "../../../lead/reducers/lead_form_reducer";
import {FinancingServicesCopyTypes} from "../../../lead/types/FinancingServicesCopyTypes";
import {OfferType} from "../../../offer/types/OfferType";
import {isPropertyWithDiscountPrice} from "../../../property/utils/is_property_with_discount_price";
import {HolidayLocationType} from "../../../types/HolidayLocationType";
import {simpleRegionsWithRenters} from "../../constants/regions_with_renters";
import {ApplicationVariant} from "../../types/ApplicationVariant";
import {IApplicationFormValues} from "../../types/IApplicationFormValues";
import {ApplicationCreditForm} from "./components/ApplicationCreditForm";
import {ApplicationDefaultForm} from "./components/ApplicationDefaultForm";
import {ApplicationRetargetingForm} from "./components/ApplicationRetargetingForm";
import {useApplicationFormContext} from "./ApplicationFormContext";

interface IProps {
    submitOnMount?: boolean;
    applicationVariant?: ApplicationVariant;
}

export const ApplicationFormBody = (props: IProps) => {
    const {submitOnMount} = props;

    const dispatch: Dispatch<ISentLeadActions> = useDispatch();

    const {offer, offerId, property, vendor, isAuthenticated, formPreviouslySent, hideQuestions, alwaysShowFields, onCloseModal, leadIsSubmitting} =
        useApplicationFormContext();

    const {values: formValues, handleSubmit} = useFormikContext<IApplicationFormValues>();

    const didSubmitOnMount = useSelector((state: IRPStore) => state.leadForm.didSubmitOnMount);

    // field visibility state
    const showTextAreaOnly = hideQuestions || offer?.type === OfferType.COMMERCIAL;
    const [showNameField, setShowNameField] = useState(true);
    const [showEmailField, setShowEmailField] = useState(true);
    const [showPhoneField, setShowPhoneField] = useState(true);
    const [showPropertyNotificationField, setShowPropertyNotificationField] = useState(true);
    const [showFinancingServicesField, setShowFinancingServicesField] = useState(false);
    const [showRentalPriceField, setShowRentalPriceField] = useState(false);

    useEffect(() => {
        if (offer && offer.id === offerId && property !== null) {
            /*
                Simplified verification whether the offer is from the region included in simpleRegionsWithRenters
                or check if offer located within the borders of the coastline then set true
            */
            if (simpleRegionsWithRenters.includes(offer.region.id) || (offer.is_holiday_location && offer.holiday_location === HolidayLocationType.SEA)) {
                setShowRentalPriceField(true);
                return;
            }
        }
    }, [offer, property]);

    const validateFieldsVisibility = () => {
        setShowNameField(alwaysShowFields || !formValues.name);
        setShowEmailField(alwaysShowFields || !formValues.email);
        setShowPhoneField(alwaysShowFields || !formValues.phone.number);
        setShowPropertyNotificationField(!formValues.new_property_notification_consent);
        setShowFinancingServicesField(!formValues.financing_services);
    };

    const showAllInputFields = () => {
        setShowNameField(true);
        setShowEmailField(true);
        setShowPhoneField(true);
    };

    useEffect(() => {
        if (formPreviouslySent) {
            validateFieldsVisibility();
        }
    }, []);

    const prevFormPreviouslySent = usePrevious(formPreviouslySent, formPreviouslySent);
    useEffect(() => {
        if (!prevFormPreviouslySent && formPreviouslySent) {
            validateFieldsVisibility();
        }
    }, [formPreviouslySent]);

    useEffect(() => {
        if (submitOnMount && !didSubmitOnMount) {
            handleSubmit();
            dispatch<ISentLeadActions>(setDidSubmitOnMountAction(true));
        }

        return () => {
            if (submitOnMount) {
                dispatch<ISentLeadActions>(setDidSubmitOnMountAction(false));
            }
        };
    }, []);

    const hasPropertyShownPrice =
        Boolean(property && isPropertyWithDiscountPrice(property)) || (!!vendor?.configuration.force_show_price && property && property.price);
    const showRevealPriceButton = hasPropertyShownPrice ? false : !offer?.stats?.ranges_price_m2_min || !!property;

    switch (props.applicationVariant) {
        case ApplicationVariant.CREDIT:
            return (
                <div css={formWrapperCreditStyle}>
                    <ApplicationCreditForm
                        onCloseModal={onCloseModal}
                        showPropertyNotificationField={showPropertyNotificationField}
                        showRentalPriceField={showRentalPriceField}
                        leadIsSubmitting={leadIsSubmitting}
                        isAuthenticated={isAuthenticated}
                        formPreviouslySent={formPreviouslySent}
                        vendor={vendor}
                        offerPrivacyPolicyUrl={offer?.configuration.privacy_policy_url}
                        offerPersonalDataProcessorName={offer?.configuration.personal_data_processor_name}
                        offerPersonalDataProcessorUrl={offer?.configuration.personal_data_processor_url}
                        copyType={!!property ? FinancingServicesCopyTypes.SINGLE_PROPERTY : FinancingServicesCopyTypes.NONE_PROPERTY}
                        offerType={offer?.type}
                    />
                </div>
            );
        case ApplicationVariant.PROPERTY_WITH_RETARGETING:
            return (
                <ApplicationRetargetingForm
                    showRevealPriceButton={showRevealPriceButton}
                    showEmailField={showEmailField}
                    showPhoneField={showPhoneField}
                    showNameField={showNameField}
                    showAllInputFields={showAllInputFields}
                    showTextAreaOnly={showTextAreaOnly}
                    showPropertyNotificationField={showPropertyNotificationField}
                    showFinancingServicesField={showFinancingServicesField}
                    showRentalPriceField={showRentalPriceField}
                />
            );
        default:
            return (
                <ApplicationDefaultForm
                    showRevealPriceButton={!!property}
                    showEmailField={showEmailField}
                    showPhoneField={showPhoneField}
                    showNameField={showNameField}
                    showAllInputFields={showAllInputFields}
                    showTextAreaOnly={showTextAreaOnly}
                    showPropertyNotificationField={showPropertyNotificationField}
                    showFinancingServicesField={showFinancingServicesField}
                    showRentalPriceField={showRentalPriceField}
                />
            );
    }
};

//  Styles

const formWrapperCreditStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: column-reverse;
    background-color: ${theme.colors.gray[100]};

    @media (min-width: ${theme.breakpoints.md}) {
        flex-direction: row;
    }
`;
