import {css} from "@emotion/react";
import {darken} from "polished";

export const prettyScroll = (scrollbarTrackBg?: string, scrollbarThumbBg?: string) => css`
    padding-right: 1px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.3);
        background-color: ${scrollbarTrackBg ? scrollbarTrackBg : "#fff"};
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${scrollbarThumbBg ? scrollbarThumbBg : "#ccc"};
        transition: background-color 0.2s ease-in-out;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: ${darken(0.06, scrollbarThumbBg ? scrollbarThumbBg : "#ccc")};
    }
`;

export const hiddenScroll = () => css`
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
`;
