import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {mb, mt, p, pb} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text-module";

import {FinancingServicesCopyTypes} from "../../../../lead/types/FinancingServicesCopyTypes";
import {hitMktOptinClick} from "../../../tracking/hit_mkt_optin_click";
import {MessageType} from "../../../types/MessageType";
import {ApplicationSource} from "../../../utils/ApplicationSource";
import {TermsAcceptation} from "../../TermsAcceptation";
import {useApplicationFormContext} from "../ApplicationFormContext";
import {MktOptinField} from "../fields/MktOptinField";
import {OptIns} from "../fields/OptIns";
import {SelectRooms} from "../fields/SelectRooms";
import {YourData} from "../fields/YourData";
import {ApplicationFormRodoTerms} from "./ApplicationFormRodoTerms";
import {ApplicationRevealButtonWithWrapper} from "./ApplicationRevealButtonWithWrapper";
import {MessageAndQuestionsSection} from "./MessageAndQuestionsSection";
import {PropertyInfo} from "./PropertyInfo";
import {SubmitButton} from "./SubmitButton";
import {VendorDisplay} from "./VendorDisplay";

interface IProps {
    showEmailField: boolean;
    showPhoneField: boolean;
    showNameField: boolean;
    showAllInputFields: () => void;
    showTextAreaOnly: boolean;
    showPropertyNotificationField: boolean;
    showFinancingServicesField: boolean;
    showRentalPriceField: boolean;
    showRevealPriceButton?: boolean;
}

export const ApplicationDefaultForm = (props: IProps) => {
    const {
        offer,
        property,
        vendor,
        hideRooms,
        hideSubmitButton,
        source,
        isAuthenticated,
        formPreviouslySent,
        onCloseModal,
        leadIsSubmitting,
        applicationMessageLogic: {
            currentState: {messageType}
        }
    } = useApplicationFormContext();

    return (
        <div css={formWrapperStyle}>
            <div css={leftSideStyle}>
                <div css={vendorHeaderStyle}>
                    <VendorDisplay offer={offer} onCloseModal={onCloseModal} vendor={vendor} css={vendorDisplayStyle} />
                </div>
                <PropertyInfo css={mb(3)} />
                {!hideRooms && <SelectRooms label="Liczba pokoi, która mnie interesuje" css={mb(2)} />}
                <YourData
                    showEmailField={props.showEmailField}
                    showPhoneField={props.showPhoneField}
                    showNameField={props.showNameField}
                    onShowAllInputFieldsClick={props.showAllInputFields}
                    css={yourDataStyle}
                />
                <MktOptinField showPropertyNotificationField={props.showPropertyNotificationField} />
            </div>
            <RightSideWrapper>
                {messageType !== MessageType.NONE && <MessageAndQuestionsSection showMessageOnly={props.showTextAreaOnly} messageType={messageType} />}

                <OptIns
                    showPropertyNotificationField={false}
                    showFinancingServicesField={props.showFinancingServicesField}
                    showPropertyRentalPriceField={props.showRentalPriceField}
                    copyType={!!property ? FinancingServicesCopyTypes.SINGLE_PROPERTY : FinancingServicesCopyTypes.NONE_PROPERTY}
                    onMktOptinClick={(event) => {
                        hitMktOptinClick(event.currentTarget.checked, 0);
                    }}
                    css={mb(2)}
                />
                <div>
                    {(!isAuthenticated || !formPreviouslySent) && <TermsAcceptation />}

                    {!hideSubmitButton &&
                        (props.showRevealPriceButton ? (
                            <ApplicationRevealButtonWithWrapper
                                leadIsSubmitting={leadIsSubmitting}
                                leadBtnDefaultText="Wyślij"
                                leadBtnRevealPriceText={property ? "Odkryj cenę" : "Odkryj ceny"}
                            />
                        ) : (
                            <SubmitButton css={mt(2)} type="submit" variant="filled_primary" disabled={leadIsSubmitting}>
                                {leadIsSubmitting ? <Loader /> : <>Wyślij</>}
                            </SubmitButton>
                        ))}

                    {source === ApplicationSource.RevealedPrices && (
                        <Text variant="body_copy_1">
                            Wypełnij formularz, żeby poznać cenę tego mieszkania. Deweloper może skontaktować się z Tobą, żeby przedstawić Ci szczegóły oferty.
                        </Text>
                    )}
                </div>

                <ApplicationFormRodoTerms offer={offer} vendor={vendor} css={leftRodoTermsStyle} />
            </RightSideWrapper>
        </div>
    );
};

//  Styles
const formWrapperStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        flex-direction: row;
    }
`;

const leftSideStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ${p(2, 2, 0, 2)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(2)};
        width: 35.4rem;
    }
`;

const vendorHeaderStyle = css`
    ${pb(3)};
    display: flex;
`;

const vendorDisplayStyle = css`
    flex: 1 1 auto;
`;

export const yourDataStyle = (theme: Theme) => css`
    margin: 0 0 1.2rem 0;

    @media (min-width: ${theme.breakpoints.md}) {
        margin: 0 0 2.6rem 0;
    }
`;

const leftRodoTermsStyle = (theme: Theme) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
    }
`;

const RightSideWrapper = styled.div`
    ${p(2)};
    ${({theme}) => css`
        @media (min-width: ${theme.breakpoints.md}) {
            background-color: ${theme.colors.gray[100]};
            width: 45.6rem;
        }
    `}
`;
