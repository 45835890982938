import {ApplicationSource, ApplicationSourceSection, getApplicationSourceDisplay} from "../../application/utils/ApplicationSource";
import {IHitApplicationData} from "../../lead/types/IHitApplicationData";
import {IOfferDetail} from "../../offer/types/IOfferDetail";
import {Country} from "../../region/types/Country";
import {getCountryName} from "../../region/utils/get_country_name";
import {getViewTypeName, ViewType} from "../../view_type/ViewType";
import {IGtmProduct} from "./common_interfaces/IGtmProduct";
import {calculateGtmDimensions, IGtmOffer, IGtmProperty, IGtmRegion, IGtmVendor} from "./calculate_gtm_dimensions";
import {hitGoogleTagManager} from "./init_google_tag_manager";

interface IGtmInquiry {
    event: "inquiry";
    ecommerce: {
        purchase: {
            actionField: {
                affiliation: string;
                id: string;
                revenue: "1.00";
                shipping: "0.00";
                tax: "0.00";
                recommended?: boolean;
            };
            products: [IGtmProduct];
        };
    };
}

interface IGtmApplication {
    financing_services: boolean;
    source: ApplicationSource;
    source_section: ApplicationSourceSection;
    uuid: string;
    direct_marketing_consent: boolean;
    new_property_notification_consent: boolean;
}

const gtmInquiry = (
    viewType: ViewType | null,
    application: IGtmApplication,
    vendor?: IGtmVendor,
    region?: IGtmRegion,
    offer?: IGtmOffer & {
        status: IOfferDetail["status"];
    },
    property?: IGtmProperty,
    recommendedApplication?: boolean,
    affiliation?: string,
    customSourceSection?: string,
    country?: Country
): boolean => {
    // calculate product

    const product: IGtmProduct = {
        ...calculateGtmDimensions(
            application.financing_services ? "tak" : "nie",
            region,
            offer,
            property,
            application.source_section,
            customSourceSection,
            application.new_property_notification_consent ? "tak" : "nie"
        ),
        name: (property && property.number) || "na",
        id: property ? property.id.toString() : "na",
        home_listing_id: offer ? (property ? `${offer.id}-${property.id}` : `${offer.id}-0`) : "na",
        group_id: offer ? `${offer.id}-0` : "na",
        price: "1.00",
        brand: vendor ? `${vendor.id}/${vendor.name}` : "na",
        category: offer ? `${offer.id}/${offer.name}` : "na",
        variant: viewType ? getViewTypeName(viewType) : "na",
        quantity: 1,
        custom_country: getCountryName(country)
    };
    // prepare gtm data and hit
    const gtmData: IGtmInquiry = {
        event: "inquiry",
        ecommerce: {
            purchase: {
                actionField: {
                    affiliation: affiliation || getApplicationSourceDisplay(application.source),
                    id: application.uuid,
                    revenue: "1.00",
                    shipping: "0.00",
                    tax: "0.00",
                    recommended: !!recommendedApplication
                },
                products: [product]
            }
        }
    };

    hitGoogleTagManager(gtmData);
    return true;
};

/**
 * Specific actions
 */

export const gtmInquiryOnApplicationSuccess = (
    application: IGtmApplication,
    data: IHitApplicationData,
    viewType: ViewType | null,
    recommendedApplication?: boolean,
    affiliation?: string,
    customSourceSection?: string
) => {
    if (application.uuid == null) {
        return console.error("gtmInquiryOnApplicationSuccess: application UUID is not defined");
    }

    const vendorData = (data.vendor && data.vendor.detail) || undefined;
    const offerData = (data.offer && data.offer.detail) || undefined;
    const finalOfferData = offerData ? {...offerData, region: offerData.region.id} : offerData;
    const regionData = (offerData && offerData.region) || undefined;
    const propertyData = (data.property && data.property.detail) || undefined;
    const country = (data.offer && data.offer.detail?.region.country) || undefined;

    if (vendorData == null) {
        return console.error("gtmInquiryOnApplicationSuccess: require vendor data");
    }

    return gtmInquiry(
        viewType,
        application,
        vendorData,
        regionData,
        finalOfferData,
        propertyData,
        recommendedApplication,
        affiliation,
        customSourceSection,
        country
    );
};
