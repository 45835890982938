import classNames from "classnames";

import {CheckboxCheckedIcon, CheckboxHalfCheckedIcon, CheckboxIcon} from "@pg-design/icons";

import {checkboxSizeValue} from "../utils/CheckboxSize";
import {ICheckboxProps} from "./Checkbox";

import {checkboxCheckedMark, checkboxCheckedMarkSvg, checkboxFrame, checkboxFrameSvg, checkboxWrapStyle} from "./CheckboxDisplay.module.css";

interface IProps extends Pick<ICheckboxProps, "readOnly" | "size" | "checked" | "checkedMarkColor"> {}

export const CheckboxDisplay = ({size = "md", ...props}: IProps) => {
    const {readOnly, checked, checkedMarkColor} = props;

    const checkboxContainerStyle = {width: checkboxSizeValue[size], height: checkboxSizeValue[size]};
    const opacity = {"--opacity": checked ? 1 : 0};
    const checkboxFrameFill = readOnly ? "var(--colors-gray-300)" : "var(--colors-gray-700)";
    const checkboxCheckedMarkFill = readOnly ? "var(--colors-gray-300)" : checkedMarkColor || "var(--colors-secondary)";

    const checkboxFrameCN = classNames(checkboxFrame, checkboxFrameFill);
    const checkboxCheckedMarkCN = classNames(checkboxCheckedMark, checkboxCheckedMarkFill);

    return (
        <div className={checkboxWrapStyle}>
            <div style={checkboxContainerStyle}>
                <div className={checkboxFrameCN}>
                    <CheckboxIcon className={checkboxFrameSvg} fill={checkboxFrameFill} />
                </div>
                <div className={checkboxCheckedMarkCN} style={opacity}>
                    {checked === "half-checked" ? (
                        <CheckboxHalfCheckedIcon className={checkboxCheckedMarkSvg} fill={checkboxCheckedMarkFill} />
                    ) : (
                        <CheckboxCheckedIcon className={checkboxCheckedMarkSvg} fill={checkboxCheckedMarkFill} />
                    )}
                </div>
            </div>
        </div>
    );
};
