import React from "react";
import {css} from "@emotion/react";

import {m} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {rpAppLink} from "@pg-mono/rp-routes";

export const FinancingServicesCheckboxPopoverText = () => {
    return (
        <>
            <Text as="p" variant="info_txt_2" css={m(0, 0, 0.5, 0)}>
                Proszę o telefoniczny i niezobowiązujący kontakt ze strony specjalisty Credipass sp. z o.o. Zgodę mogę zawsze odwołać.
            </Text>

            <Text as="p" variant="info_txt_2" css={m(0, 0, 0.5, 0)}>
                <a href={rpAppLink.external.crediPassTOS} target="_blank" css={popoverLink}>
                    Klauzula informacyjna Credipass sp. z o.o. o ochronie danych osobowych
                </a>
            </Text>
        </>
    );
};

// Styles
const popoverLink = css`
    color: #fff;

    &:focus,
    &:hover {
        color: #fff;
    }
`;
