/* eslint-disable @typescript-eslint/no-unused-vars */
import {IABTestConfiguration} from "@pg-mono/ab-testing";
import {rpAppPath} from "@pg-mono/rp-routes";

export enum OngoingABTests {
    PLANER_VERIFICATION = "PLANER_VERIFICATION",
    MULTI_LEAD_SUMMARY = "MULTI_LEAD_SUMMARY"
}

/**
 * Example of a test configuration:
 * {
 *     id: "xyz123" // Generated id, received from business,
 *     expires: new Date(2024, 3, 30), // Date when the test will expire,
 *     name: OngoingABTests.SOME_FEATURE, // Name of the test,
 *     path: ["some-path"], // Paths where the test will be active,
 *     matchOnAllPaths: false, // If true, the test will be active on all paths,
 *     variants: 3, // Number of variants, the actual amount is variants + 1 (first variant is 0)
 *     withSSR: false // If true, the test will be active on server side rendering
 * }
 */

export const ongoingABTests: IABTestConfiguration[] = [
    {
        id: "Zy66k222zk71O9b0bD8y",
        expires: new Date(2025, 8, 1),
        name: OngoingABTests.PLANER_VERIFICATION,
        path: [],
        matchOnAllPaths: true,
        variants: 1,
        withSSR: true
    },
    {
        id: "Yy99k333zk71O3b0bG1y",
        expires: new Date(2025, 9, 1),
        name: OngoingABTests.MULTI_LEAD_SUMMARY,
        path: [],
        matchOnAllPaths: true,
        variants: 2,
        withSSR: false
    }
];
