import React from "react";
import {css, useTheme} from "@emotion/react";

import {flexAbsoluteCenter, mt} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

interface IProps {
    className?: string;
}

export const ApplicationSentSuccess = (props: IProps) => {
    const {className} = props;
    const theme = useTheme();

    return (
        <div css={applicationPropertyPriceSentSuccess} className={className}>
            <BrandCheckboxCheckedIcon size="11" wrapperColor={theme.colors.primary} wrapperSize="11.2" />

            <Text as="div" css={mt(4)} mb="1.6rem" variant="headline_3" align="center">
                Twoje zapytanie zostało wysłane
            </Text>
            <Text>Oczekuj na kontakt od dewelopera</Text>
        </div>
    );
};

const applicationPropertyPriceSentSuccess = css`
    ${flexAbsoluteCenter};
    flex-direction: column;
`;
