import {ApplicationSourceSection, getApplicationSourceSectionDisplay} from "../../application/utils/ApplicationSource";
import {getOfferTypeName} from "../../offer/helpers/OfferType";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {IOfferDetail} from "../../offer/types/IOfferDetail";
import {OfferType} from "../../offer/types/OfferType";
import {getCountryName} from "../../region/utils/get_country_name";
import {IRangeQuery} from "../../request/IRangeQuery";

export interface IGtmDimensions {
    dimension1: string;
    dimension2: string;
    dimension3: string;
    dimension4: string;
    dimension5: string;
    dimension6: string;
    dimension8: string;
    dimension9: string;
    dimension11: string;
    dimension13: string;
    dimension14: string;
    dimension15: string;
    dimension16: string;
    dimension17: string;
    dimension18: string;
}
export interface IGtmRegion {
    id: number;
    stats: {
        region_type_city: IRegionAncestor | null;
        region_type_county: IRegionAncestor | null;
        region_type_district: IRegionAncestor | null;
        region_type_town: IRegionAncestor | null;
        region_type_voivodeship: IRegionAncestor | null;
    };
    country: number;
}
export interface IGtmVendor {
    // not needed in this file
    id: number;
    name: string;
}
export interface IGtmOffer {
    construction_date_range: IRangeQuery<string>;
    id: number;
    name: string;
    region: number;
    status?: IOfferDetail["status"];
    stats: {
        ranges_rooms_min: number | null;
        ranges_rooms_max: number | null;
    } | null;
    type: OfferType;
    vendor: number;
}
export interface IGtmProperty {
    floor: number | null;
    id: number;
    is_luxury: boolean;
    number: string;
    rooms: number | null;
    region?: number;
}

export const calculateGtmDimensions = (
    financingServices: "tak" | "nie" | "na",
    region?: IGtmRegion,
    offer?: IGtmOffer,
    property?: IGtmProperty,
    sourceSection?: ApplicationSourceSection,
    customSourceSection?: string,
    marketingConsent?: "tak" | "nie"
): IGtmDimensions => {
    const countryName = getCountryName(region?.country, "pl");

    return {
        // voivodeship
        dimension1: region?.stats?.region_type_voivodeship?.name?.toLocaleLowerCase() ?? "na",
        // city || family
        dimension2: region?.stats?.region_type_city?.name ?? region?.stats?.region_type_county?.name?.toLocaleLowerCase() ?? "na",
        // district || town || na
        dimension3: region?.stats?.region_type_district?.name ?? region?.stats?.region_type_town?.name?.toLocaleLowerCase() ?? "na",
        // offer type (mieszkanie/dom/lokal użytkowy)
        dimension4: (offer && getOfferTypeName(offer.type)?.toLocaleLowerCase()) || "na",
        // region id
        dimension5: (offer && offer.region.toString()) || "na",
        // offer upper construction end date (dd.mm.YYYY)
        dimension6: (offer && offer.construction_date_range.upper) || "na",
        // property floor || "na"
        dimension8: (property && typeof property.floor === "number" && property.floor.toString()) || "na",
        // property is luxury - "tak"/"nie"
        dimension9: property ? (property.is_luxury ? "tak" : "nie") : "na",
        // source section of a lead - translated "ApplicationSourceSection" / "na"
        dimension11: customSourceSection || (sourceSection ? getApplicationSourceSectionDisplay(sourceSection) : "na"),
        // offer stats rooms range (rooms_0=lower&rooms_1=upper) || property rooms (4)
        dimension13: property
            ? typeof property.rooms === "number"
                ? property.rooms.toString()
                : "na"
            : offer
              ? `${offer.stats?.ranges_rooms_min}-${offer.stats?.ranges_rooms_max}`
              : "na",
        // last financingServices - "tak"/"nie"
        dimension14: financingServices,
        // "deweloper"/"inwestycja"/"nieruchomość"
        dimension15: property ? "nieruchomość" : offer ? "inwestycja" : "deweloper",
        // Kraj
        dimension16: countryName || "na",
        //marketingConsent
        dimension17: marketingConsent || "na",
        // settlement in the efficiency model vs abonament model
        dimension18: getSettlementModel(offer)
    };
};

function getSettlementModel(offer?: IGtmOffer) {
    if (!offer || !offer.status) {
        return "na";
    }

    if (offer.status.substatus === 6) {
        return "tak";
    }

    if (offer.status.substatus === 7) {
        return "nie";
    }

    return "na";
}
