import React, {forwardRef, ReactNode, useEffect} from "react";
import classNames from "classnames";

import {useIsMounted} from "@pg-mono/hooks";

import {CheckboxSize} from "../utils/CheckboxSize";
import {CheckboxDisplay} from "./CheckboxDisplay";

import {checkboxWrapperStyle, componentContainerStyle, hiddenInputStyle, labelStyle} from "./Checkbox.module.css";

export type ICheckboxProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "checked" | "type" | "onChange" | "size"> & {
    name: string;
    checked: boolean | "half-checked";
    id?: string;
    className?: string;
    onChange: (name: string, checked: boolean, value: HTMLInputElement["value"]) => void;
    onAfterChange?: (name: string, checked: boolean, value: HTMLInputElement["value"]) => void;
    checkedMarkColor?: string;
    size?: CheckboxSize;
} & ILabelScenarios;

//  Label scenarios - provide aria-labelledby if you're not providing a labelContent
type ILabelScenarios = INoLabelScenario | ILabelProvidedScenario;

type INoLabelScenario = {
    labelContent?: never;
    "aria-labelledby": string;
};

type ILabelProvidedScenario = {
    labelContent: ReactNode | string;
    "aria-labelledby"?: never;
};

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>((props, ref) => {
    const {checked, onChange, id, readOnly, labelContent, onAfterChange, size = "md", checkedMarkColor, onClick, ...inputProps} = props;

    const isMounted = useIsMounted();
    useEffect(() => {
        if (onAfterChange && isMounted) {
            onAfterChange?.(props.name, !!props.checked, props.value as HTMLInputElement["value"]);
        }
    }, [props.checked]);

    const classname = classNames(componentContainerStyle, props.className);

    return (
        <div className={classname}>
            <div className={checkboxWrapperStyle}>
                <input
                    {...inputProps}
                    onClick={(e) => {
                        e.stopPropagation();

                        if (onClick) {
                            onClick(e);
                        }
                    }}
                    type="checkbox"
                    checked={!!checked}
                    onChange={({target: {name, checked, value}}) => {
                        if (!readOnly) {
                            onChange(name, checked, value);
                        }
                    }}
                    id={id}
                    ref={ref}
                    className={hiddenInputStyle}
                />
                <CheckboxDisplay checked={checked} checkedMarkColor={checkedMarkColor} readOnly={readOnly} size={size} />
            </div>
            {labelContent && (
                <label className={labelStyle} htmlFor={id}>
                    {labelContent}
                </label>
            )}
        </div>
    );
});
