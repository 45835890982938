import React, {useId, useMemo} from "react";
import {css, Theme} from "@emotion/react";

import {Checkbox} from "@pg-design/checkbox-module";
import {elevation} from "@pg-design/elevation";
import {flexAbsoluteCenter, m} from "@pg-design/helpers-css";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {RodoText} from "../../../../../lead/components/common/RodoText";
import {getOfferTypeNameCapital} from "../../../../../offer/helpers/OfferType";
import {OfferType} from "../../../../../offer/types/OfferType";
import {ApplicationOfferBoxBase} from "./ApplicationOfferBoxBase";

interface IProps {
    propertyId: number;
    offerAddress: string;
    offerMainImg?: string;
    offerType: OfferType;
    rooms?: number;
    area?: number;
    offerVendorName: string;
    offerVendorLogo?: string;
    offerPrivacyPolicyUrl: string | null;
    offerPrivacyDataProcessorName: string | null;
    offerPersonalDataProcessorUrl: string | null;
    selectedPropertyIds: number[];
    togglePropertySelection: (propertyId: number) => void;
}

export const ApplicationOfferSelectionBox = (props: IProps) => {
    const checkboxId = useId();
    const checked = useMemo(() => props.selectedPropertyIds.includes(props.propertyId), [props.selectedPropertyIds, props.propertyId]);

    const displayType = getOfferTypeNameCapital(props.offerType);

    return (
        <ApplicationOfferBoxBase
            css={[boxWrapperStyle, checked && boxWrapperCheckedStyle]}
            title={`${displayType}, ${props.rooms ? `${props.rooms} ${roomsPluralize(props.rooms)}, ` : ""}${props.area ? areaFormat(props.area) : ""}`}
            address={props.offerAddress}
            imgSrc={props.offerMainImg}
            content={
                <>
                    <ApplicationOfferBoxBase.Logo imgSrc={props.offerVendorLogo} alt={props.offerVendorName} css={m(2, 0, 2)} />
                    <RodoText
                        textVariant="info_txt_3"
                        vendorName={props.offerVendorName}
                        privacyPolicyUrl={props.offerPrivacyPolicyUrl}
                        privacyDataProcessorName={props.offerPrivacyDataProcessorName}
                        personalDataProcessorUrl={props.offerPersonalDataProcessorUrl}
                    />
                </>
            }
            coverCss={coverStyle}
            rightSlot={
                <div css={rightSlotStyle}>
                    <label htmlFor={checkboxId} css={[checkboxWrapperStyle, checked && checkboxCheckedWrapperStyle]}>
                        <Checkbox
                            onChange={() => {
                                props.togglePropertySelection(props.propertyId);
                            }}
                            checked={checked}
                            labelContent=""
                            id={checkboxId}
                            name={checkboxId}
                            checkedMarkColor="#02D054"
                        />
                    </label>
                </div>
            }
        />
    );
};

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

const boxWrapperStyle = (theme: Theme) => css`
    position: relative;
    margin-right: 2.7rem;
    border: 1px solid ${theme.colors.gray[500]};
    transition: all 0.2s ease-in-out;
`;

const boxWrapperCheckedStyle = css`
    ${elevation(4)};
    border-color: transparent;
`;

const coverStyle = css`
    margin: -1px;
    flex: 0 0 123px;

    img {
        width: 123px;
    }
`;

const rightSlotStyle = css`
    width: 2.7rem;
`;

const checkboxWrapperStyle = (theme: Theme) => css`
    position: absolute;
    right: -2.7rem;
    top: 50%;
    transform: translateY(-50%);
    ${flexAbsoluteCenter};
    width: 5.4rem;
    height: 5.4rem;
    background-color: ${theme.colors.gray[100]};
    border: 1px solid ${theme.colors.gray[500]};
    border-radius: 2.7rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
`;

const checkboxCheckedWrapperStyle = css`
    ${elevation(1)};
    border-color: transparent;
`;
