import React from "react";

import {FormikForm} from "@pg-design/formik-utils";

import {useApplicationFormContext} from "../ApplicationFormContext";

interface IProps {
    className?: string;
}

export const ApplicationMessage = (props: IProps) => {
    const {hideMessageAndQuestionsSection, onFormControlClick, isMobile} = useApplicationFormContext();

    return (
        <>
            {!hideMessageAndQuestionsSection && (
                <FormikForm.Textarea
                    rows={isMobile ? 6 : 12}
                    name="text"
                    onClick={onFormControlClick}
                    className={props.className}
                    placeholder="Wpisz własną wiadomość"
                />
            )}
        </>
    );
};
