import React from "react";
import {css, useTheme} from "@emotion/react";

import {borderRadius, calculateRemSize, onDesktop, p} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {getOfferTypeNameCapital} from "../../../../../offer/helpers/OfferType";
import {OfferType} from "../../../../../offer/types/OfferType";

interface IProps {
    offerAddress: string;
    offerMainImg?: string;
    offerType: OfferType;
    rooms?: number;
    area?: number;
    offerVendorName: string;
    offerVendorLogo?: string;
    className?: string;
}

export const ApplicationOfferBigBox = (props: IProps) => {
    const theme = useTheme();
    const offerTitle = `${getOfferTypeNameCapital(props.offerType)}, ${props.rooms ? `${props.rooms} ${roomsPluralize(props.rooms)}, ` : ""}${props.area ? areaFormat(props.area) : ""}`;

    return (
        <div css={wrapperStyle} className={props.className}>
            <div css={offerHeaderStyle}>
                <div css={vendorLogoWrapperStyle}>
                    <Image src={props.offerVendorLogo} alt={props.offerVendorName} width="72px" height="54px" css={vendorLogoImgStyle} />
                </div>
                <div>
                    <Text variant="headline_6" mb="0.4rem">
                        {offerTitle}
                    </Text>
                    <Text variant="info_txt_1" color={theme.colors.gray[700]}>
                        {props.offerAddress}
                    </Text>
                </div>
            </div>
            <div css={coverImgWrapperStyle}>
                <Image src={props.offerMainImg} alt={offerTitle} width="100%" height="auto" css={coverImgStyle} />
            </div>
        </div>
    );
};

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

const wrapperStyle = css`
    ${borderRadius(2)};
    overflow: hidden;
    background-color: #fff;
`;

const vendorLogoWrapperStyle = css`
    display: flex;
    flex: 0 0 72px;

    ${onDesktop(css`
        flex: 0 0 93px;
    `)};
`;

const vendorLogoImgStyle = css`
    width: 72px;
    height: 54px;

    & > img {
        width: 100%;
        height: 100%;
    }

    ${onDesktop(css`
        width: 93px;
        height: 70px;
    `)};
`;

const offerHeaderStyle = css`
    ${p(1)};
    display: flex;
    flex-direction: row;
    gap: ${calculateRemSize(2)};

    ${onDesktop(css`
        ${p(2)};
    `)};
`;

const coverImgWrapperStyle = css`
    display: flex;
`;

const coverImgStyle = css`
    height: 30rem;
    width: 100%;

    & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    ${onDesktop(css`
        height: 40rem;
    `)};
`;
